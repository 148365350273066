<template>
	<div id="presentation" class="container" align="right">
		<h2>Formations</h2>
			<!--<div class="container">
				<h3 class="info" align="center"> Solution 1 </h3>
				<span class="row container" id="informations " v-for="etude in etudes " :key="etude.annee">
					
					<div class="col-md-6 col-sm-6" >
						
							<p>{{etude.annee}} </p>
					
							<p> {{etude.diplome}} </p>
						
							<p >  {{etude.etablissement}} </p>
						
							<p >  {{etude.ville}} </p>
					
							<p >  {{etude.Remarque}} </p>
							<p id="sepa"></p> 
					</div>
					<div  class="col-md-6 col-sm-6">
						<b-img id="ligne" :src="getImgUrl(etude.image)" />
						<p id="sepa"></p> 
					</div>
				</span>
			</div>-->
			<div class="container  ">
				<br>
				<!-- <h3 align="center"> Solution 2 </h3> -->
				<div class="timeline  frise " id="informations" align="center" v-for="(etude,index) in etudes " :key="etude.annee">
					<div class="container cont left" v-if="index%2==0">
						<div class="content">
							<h2 class="etude-annee ">{{etude.annee}} </h2>
							
								<p class="etude-annee "> {{etude.diplome}}  <br>
									{{etude.etablissement}}<br>
									{{etude.ville}} <br>
									{{etude.Remarque}}</p>
							<b-img id="ligne" :src="getImgUrl(etude.image)" />
						</div>
					</div>
					<div class="container cont right" v-if="index%2!=0">
						<div class="content">
							<h2 class="etude-annee">{{etude.annee}} </h2>
							<p class="etude-annee "> {{etude.diplome}}  <br>
								{{etude.etablissement}}<br>
								{{etude.ville}} <br>
								{{etude.Remarque}}</p>
								<b-img id="ligne" :src="getImgUrl(etude.image)" />
						</div>
					</div>
				</div>

			</div>
	</div>

</template>

<script>
export default {
	name:'Formations',
	data(){
		return{
			etudes : [
				{
					annee : 'Sept. 2011 - Juin 2015',
					diplome : 'Brevet ',
					etablissement : 'Collège Joseph Roumanille',
					ville:'Avignon',
					Remarque : 'Brevet Mention Bien + Option Euro anglais',
					image:'roumanille.png'
				},
				{
					annee : 'Sept. 2015 - Juin 2018',
					diplome : 'Baccalaureat Scientifique',
					etablissement : 'Lycée Frédéric Mistral',
					ville:'Avignon',
					Remarque : 'Mention Bien ',
					image:'mistral.jpg'
				},
				{
					annee : 'Sept. 2018 - Juin 2021',
					diplome : 'Licence Informatique',
					etablissement : 'Université Avignon',
					ville:'Avignon',
					Remarque : '',
					image:'logoCeri.jpg'
				},
				
				


			]
		}
	},
	methods:{
		getEtude(){
			return this.etudes;
		},
		getImgUrl(pic) {
			return require('../assets/'+pic)
		}
	}
}
</script>


<style >
	@import url(../assets/stylesheet_css/timelineV.css);
</style>